<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading videos
  </loading-spinner>
  <div
    class="shadow p-3 rounded text-center fst-italic text-muted"
    v-else-if="videos.length == 0"
  >
    <small>No video found</small>
  </div>
  <div v-else>
    <div
      class="rounded bg-white p-3 mb-3 border"
      v-for="video in videos"
      :key="video._id"
    >
      <div class="bg-info px-3 py-2 mb-3 fw-medium rounded">
        {{ video.title }}
      </div>
      <video
        id="video"
        :src="Helper.formatMediaUrl(video.video)"
        class="w-100 h-100 rounded"
        controls
      />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      videos: [],
    };
  },
  methods: {
    async getCRTVideos() {
      return new Promise(async (resolve) => {
        this.isLoading = true;

        try {
          const { data } = await this.API.get("crt-videos?_limit=-1");

          this.videos = data.filter((d) => d.video);
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
  },
  async mounted() {
    await this.getCRTVideos();
  },
};
</script>